import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import PageTitle from '../components/visual/PageTitle';


const Tools = () => {
    return (
        <Layout>
            <Seo title="Nástroje" />
            <PageTitle>Nástroje</PageTitle>
        </Layout>
    )
}

export default Tools;